import React from 'react';
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import authorization from "../../../../authorization";
import updateUserSettings from '../../../../actions/profile/updateUserSettings';
import getUserInfo from "../../../../actions/profile/getUserInfo";
import NewAppOptionsForm from "./NewAppOptionsForm";
import showAppOptions from "../../../../actions/info/showAppOptions";
import _ from "lodash";

class NewAppOptions extends React.Component {

  componentDidMount()
  {
    this.props.getUserInfo([],[],[(data)=>this.initialize(data.user)]);
  }

  componentDidUpdate(prevProps, prevState, snapshot)
  {
    if (!prevProps.visible && this.props.visible)
      this.initialize(this.props.user);
  }

  initialize=(data)=>
  {
    this.props.initialize(_.pick(data,["dontShowSignAndShare","dontShowcreateCertificate", "defaultTransfer", "removeFormFields","filesView"]));
  };

  onSubmit = (form) =>
  {
    this.props.showAppOptions(false);
    Object.keys(form).forEach((key)=> this.props.user[key]===form[key] && delete form[key]);
    if(Object.keys(form).length>0)
      this.props.updateUserSettings(form);
  }

  render()
  {
    return <nav>
      <NewAppOptionsForm mode={'horizontal'}  {...this.props} onSubmit={this.onSubmit}/>
    </nav>;

  }
}


function mapStateToProps(state) {
  return {
    visible: state.locate.visibleAppOptions,
    language: state.auth.language,
    user: state.user,
    dimApp: state.locate.dimensionsApp,
    netcoPKI: state.auth.netco,
    registration: state.registration
  }
}

export default authorization(connect(mapStateToProps, { updateUserSettings, showAppOptions, getUserInfo})(reduxForm({ form: 'new_app_options_form' })(NewAppOptions)));
