import React from 'react';
import {connect} from "react-redux";

// import '@ant-design/compatible/assets/index.css';


import {Form,Button, Card, Col, Row, Select} from "antd";
import {Field, formValueSelector, reduxForm} from "redux-form";
import _ from 'lodash';
import fillPendingPDF from "../../../actions/files/fillPendingPDF";
import signPendingFiles from "../../../actions/files/signPendingFiles";
import updateUserSettings from '../../../actions/profile/updateUserSettings';
import moment from "moment";
import TitleAndInfo from "../../Helpers/TitleAndInfo";
import redirect from "../../../actions/auth/redirect";
import signature from "../../../const/signature";
import {ROOT_ROUTE, SIGNATURE_FIELDS_ROUTE} from "../../Root/routes";
import {CreateCheckbox, CreateSelectField, CreateTextField} from "../../Helpers/Input/InputCreator";
import {required} from "../../Helpers/Input/validations";
import clearFiles from "../../../actions/files/clearFiles";
import {onlyReadOnlyFields} from "../../Helpers/utils";

class TemplateFields extends React.Component {

  state = {fields: {}}

  componentWillUnmount()
  {
    if(this.props.location.pathname===SIGNATURE_FIELDS_ROUTE)
      this.props.clearFiles();
  }

  componentDidMount() {
    if(this.props.signatureFiles.selectedTemplateName==='')
    {
      this.props.redirect(ROOT_ROUTE);
      return;
    }
    const fields=this.props.signatureFiles.fields;
    if(fields.length<=0)
    {
      this.handleSubmit({formFlattening:false});
      return;
    }
    if(onlyReadOnlyFields(fields))
    {
      const formFlattening=!this.props.signatureFiles.isSigned && this.props.user.removeFormFields;
      this.handleSubmit({formFlattening});
      return;
    }
    this.props.initialize(this.props.initialValues);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(Object.keys(prevProps.initialValues).length !== Object.keys(this.props.initialValues).length)
      this.props.initialize(this.props.initialValues);
    this.props.signatureFiles.fields.forEach(field => {
      if(field.name && field.defaultValue.startsWith('NS') && !field.defaultValue.startsWith('NSNOW'))
        this.props.change(field.name, this.props[field.name] ? this.props[field.name] : '');
    });
    if(prevProps.signatureFiles.selectedTemplateName!==this.props.signatureFiles.selectedTemplateName && onlyReadOnlyFields(this.props.signatureFiles.fields))
    {
      const formFlattening=!this.props.signatureFiles.isSigned && this.props.user.removeFormFields;
      this.handleSubmit({formFlattening});
    }
    if(this.props.user.removeFormFields !== prevProps.user.removeFormFields)
      this.props.initialize(this.props.initialValues);
    
  }

  handleSubmit = formValues =>
  {
    const pendingFiles=this.props.signatureFiles.pending;
    if(!pendingFiles)
    {
      console.log("No pending file to fill");
      this.props.redirect(ROOT_ROUTE);
      return;
    }
    const currentFile=pendingFiles.find((pendingFile)=>pendingFile.uid===this.props.signatureFiles.currentFileUID);
    currentFile.filled=true;
    this.props.fillPendingPDF(currentFile.uid,currentFile.password,formValues,
        ()=>this.props.signPendingFiles(this.props.signatureFiles.pending,this.props.signatureFiles.callback));
  }

  changeRemoveFormFields = value => {
    const newValue = !value.target.value;
    this.props.updateUserSettings({"removeFormFields":newValue});
  }

  renderPDFFields=(fields)=>
  {
    let isFirst=true;
    let rows=[];
    let cols=[];
    let spanLabelSize=8,fieldSize=16;
    if(window.innerWidth<600)
      spanLabelSize=fieldSize=24;
    fields.map((item, index) =>
    {
      if(item.name==='formFlattening')
        console.warn("Campo formFlattening detectado, solo puede haber uno");
      let fieldToReturn=<Field key={item.name} name={item.name} component={CreateTextField} disabled={item.readOnly} placeholder={item.tooltip} validate={item.required ? [required] : []} hasFeedback/>;
      if (item.type !== 4)
      {
        if(item.values && item.values.length>1)
          fieldToReturn = <Field key={item.name} name={item.name} component={CreateSelectField} disabled={item.readOnly} validate={item.required ? [required] : []} hasFeedback>
            {item.values.map(item => <Select.Option key={index+"sel"} value={item}>{item}</Select.Option>)}
          </Field>;
        else
          fieldToReturn = <Field key={item.name} name={item.name} component={CreateCheckbox} disabled={item.readOnly} validate={item.required ? [required] : []} hasFeedback/>;
      }
      const display=(item.readOnly && item.defaultValue==='')?'none':'';
      cols.push(<Col key={index+"l"} style={{marginTop:7,display:display}} span={spanLabelSize}>{item.tooltip}</Col>);
      cols.push(<Col key={index+"v"} style={{marginTop:7,display:display}} span={fieldSize}>{fieldToReturn}</Col>);
      if (isFirst)
      {
        isFirst=false;
        if(index+1===fields.length)
        {
          rows.push(cols);
          cols=[];
          isFirst=true;
        }
      }
      else
      {
        rows.push(cols);
        cols=[];
        isFirst=true;
      }
      return <Col key={index} xs={24} md={12}>{fieldToReturn}</Col>;
    });
    let result=rows.map((row,index)=><Row key={index+"r"} gutter={24}>{row}</Row>);
    return <div>{result}</div>;
  };

  render () {
    return (
      <Card bordered={false} className={'signature-wizard'}>
        <TitleAndInfo title={this.props.signatureFiles.selectedTemplateName}
                      info={signature[this.props.language].templateField_message}/>
          <Form onFinish={this.props.handleSubmit(this.handleSubmit)}>
            {this.renderPDFFields(this.props.signatureFiles.fields)}
            <Row>
              <Form.Item style={{width:'100%'}}>
              <Field name="formFlattening"
                     type={'checkbox'}
                     component={CreateCheckbox}
                     disabled={this.props.isSigned}
                     hasFeedback
                     onChange={this.changeRemoveFormFields}>
                {signature[this.props.language].flat}
              </Field>
              </Form.Item>
            </Row>
            <Row>
              <Button htmlType={'submit'} type={'primary light-primary rounded-sm float-left'}>{signature[this.props.language].generate}</Button>
            </Row>
          </Form>
      </Card>
    );
  }
}

const selector = formValueSelector('template_fields_form');

const mapStateToProps = state => {
  const isSigned=state.signatureFiles.isSigned;
  const formFlattening=!isSigned && state.user.removeFormFields;
  let map =  {
    language: state.auth.language,
    location:state.router.location,
    signatureFiles: state.signatureFiles,
    user:state.user,
    isSigned: isSigned,
    initialValues: _.reduce(state.signatureFiles.fields, (result, value) => {
      result[value.name] = value.defaultValue.startsWith('NSNOW') ? moment().format('DD/MM/YYYY') : value.defaultValue.startsWith('NS') ? '' : value.defaultValue;
      return result;
    }, {formFlattening:formFlattening})
  };

  state.signatureFiles.fields.forEach(field => {
    if(field.defaultValue.startsWith('NS') && !field.defaultValue.startsWith('NSNOW')){
      let defaultValue = field.defaultValue.substring(2);
      if (defaultValue.includes("-")) {
        let components = defaultValue.split("-");
        map[field.name] = parseFloat(selector(state, components[0])) - parseFloat(selector(state, components[1]));
      } else if (defaultValue.includes("+")) {
        let components = defaultValue.split("+");
        map[field.name] = parseFloat(selector(state, components[0])) + parseFloat(selector(state, components[1]));
      } else if (defaultValue.includes("/")) {
        let components = defaultValue.split("/");
        map[field.name] = parseFloat(selector(state, components[0])) / parseFloat(selector(state, components[1]));
      } else if (defaultValue.includes("*")) {
        let components = defaultValue.split("*");
        map[field.name] = parseFloat(selector(state, components[0])) * parseFloat(selector(state, components[1]));
      }
    }
  });
  return map;
};

export default connect(mapStateToProps, {redirect,signPendingFiles,fillPendingPDF,clearFiles, updateUserSettings})(reduxForm({
  form: 'template_fields_form',
})(TemplateFields));
