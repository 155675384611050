import netcoServer from '../../apis/netcoServer';
import handleResponse from "../helpers/handleResponse";
import {GET_USER_INFO, UPDATE_PENDING_FILES_COUNT} from "../types";
import _ from 'lodash';
import loadingCall from "../helpers/loadingCall";

const getUserInfo= (postCallBacks=[],postErrors=[], postCallBacksData=[]) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getUserInfo', {}));

  const onSuccessPayload = data => {
    dispatch({type: UPDATE_PENDING_FILES_COUNT,payload:{pendingFileList:{localTotal:data.result.pendingFilesCount}}});
    return {
      user: _.pick(data.result, ['userName',
        'mail',
        'telefono',
        'idKey',
        'reason',
        'tsid',
        'publish',
        'certification',
        'cedula',
        'direccion',
        'country',
        'state',
        'city',
        'serialNumber',
        'serialNumberHex',
        'zipCode',
        'hasCertificate',
        'cert',
        'certExp',
        'useImage',
        'fingerPrint',
        'includeUserList',
        'hasFingerprint',
        'image',
        'txtExtensions',
        'maxFileSize',
        'hasJustPublicKey',
        'enableecom',
        'usedOperations',
        'totalOperations',
        'imageExtensions',
        'lly',
        'llx',
        'urx',
        'ury',
        'p7z',
        'previewPDF',
        'operation',
        'mails',
        'helper',
        'fillpdf',
        'pageNumber',
        'pendingFilesCount',
        'defaultKey',
        'wordpressUser',
        'countDocuments',
        'signAllPages',
        'cn',
        'caDescription',
        'caID',
        'filesView',
        'dontShowcreateCertificate',
        'dontShowSignAndShare',
        'sidxPending',
        'sordPending',
        'resultsPending',
        'resultsWorkflow',  
        'resultsDocs',
        'defaultTransfer',
        'removeFormFields'
      ])
    };
  };
  handleResponse(dispatch, response, GET_USER_INFO, onSuccessPayload,null,[],postCallBacks,postErrors,postCallBacksData)
};
export default getUserInfo;
